import React from 'react'
import { Link } from 'gatsby'
// import { LocalizedLink as Link } from '@components/LocalizedLink'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
// import { Link } from 'gatsby-plugin-intl'

const Page404 = props => (
  <Layout {...props} locale='zh-CN'>
    <Seo title='defi.to' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          {/* {console.log('props ', props)} */}
          <PageTitle
            header='对不起，此页面不存在'
            subheader='您可能输入了错误的地址，或者页面已经被转移'
          />
        </Section>
        <Section>
          <Button variant='primary' as={Link} to='/'>
            返回主页
          </Button>
        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default Page404
